<template>
  <div>
    <div class="content">
      <div class="prompt">
        <img src="../../assets/images/LTicon.png" alt="">
        <div class="promptText">公告管理</div>
      </div>
      <div class="searchContent">
        <div class="search">
          <div class="formList">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="标题：">
                <el-input clearable v-model="formInline.title" placeholder="请输入标题查询" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item label="内容：">
                <el-input clearable v-model="formInline.content" placeholder="请输入内容查询" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item label="创建人：">
                <el-input clearable v-model="formInline.userName" placeholder="请输入创建人查询" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item label="创建时间：">
                <div class="timeAt">

                  <el-date-picker type="date" :append-to-body="false" placeholder="选择创建时间" value-format="yyyy-MM-dd"
                    class="custom-input" v-model="formInline.startTime" style="width: 100%;"></el-date-picker>
                  <div> ~ </div>
                  <el-date-picker type="date" :append-to-body="false" placeholder="选择创建时间" value-format="yyyy-MM-dd"
                    class="custom-input" v-model="formInline.endTime" style="width: 100%;"></el-date-picker>
                </div>
              </el-form-item>

              <el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <div class="newView">
            <el-button type="primary" @click="onSubmit"
              icon="el-icon-search" class="custom-button">查询</el-button>

            <el-button type="primary" @click="openViewDialog('', 0)" icon="el-icon-plus" class="custom-button"
              :class="isStringInArray('btnNewNotice') ? '' : 'btnShowAuthority'">新建公告</el-button>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" border :data="tableData" style="width: 100%"
          class="custom-table">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="tableText">
                  {{ dataLoading ? '' : '暂无数据' }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" label="序号" type="index" :index="getIndex" width="100">
          </el-table-column>
          <el-table-column align="center" prop="title" label="标题" :fluid="true">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.title)">
                <el-tooltip :content="scope.row.title" placement="top" :popper-class="'custom-tooltip'">
                  <div class="ellipsis">{{ scope.row.title }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.title }}</div>
              </template>
            </template>

          </el-table-column>
          <el-table-column align="center" prop="content" label="内容" :fluid="true">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.content)">
                <el-tooltip :content="scope.row.content" placement="top" :popper-class="'custom-tooltip'">
                  <div class="ellipsis">{{ scope.row.content }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.content }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="userName" label="创建人姓名" width="120">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="180">
          </el-table-column>
          <el-table-column align="center" prop="data" label="是否展示" width="120">
            <template slot-scope="scope">
              {{ scope.row.status == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sort" label="排序" width="120">
          </el-table-column>
          <el-table-column align="center" prop="data" fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <el-button :class="isStringInArray('btnModifyNotice') ? '' : 'btnShowAuthority'"
                @click="replyDialog(scope.row, 1)" type="text">编辑</el-button>
              <span :class="isStringInArray('btnModifyNotice') ? '' : 'btnShowAuthority'">|</span>
              <el-button :class="isStringInArray('btnDeleteNotice') ? '' : 'btnShowAuthority'"
                @click="openViewDialog1(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <div class="btn" @click="startHome">首页</div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes"
          :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
          next-button-class="custom-next-button" :total="total">
        </el-pagination>
        <div class="btn endBtn" @click="endHome">尾页</div>
        <div class="paginationEnd">
          <div>跳</div>至
          <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
            v-model.number="paginationEndIpt"></el-input>
          <div>页</div>
          <div class="btn confirm" @click="jumpPage">确定</div>
        </div>
      </div>
    </div>

    <!-- 新建公告弹窗 -->
    <el-dialog @close="handleClose" :title="updateType == 0 ? '新建公告' : '修改公告'" :visible.sync="centerDialogVisible"
      width="55%">
      <!-- <div class="newCenter">
        <span class="newCenterText">创建人：</span>
        <el-input placeholder="请输入公告标题" v-model="textarea" class="custom-input-textarea">
        </el-input>
      </div> -->
      <el-form :model="addForm" ref="addForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="公告标题" prop="title">
          <el-input class="customInput" show-word-limit maxlength="50" placeholder="请输入公告标题"
            v-model.trim="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input placeholder="请输入排序" v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否展示" prop="status">
          <el-radio-group v-model="addForm.status">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="公告内容" prop="content">
          <el-input show-word-limit maxlength="200" type="textarea" :rows="8" placeholder="请输入公告内容"
            v-model.trim="addForm.content"></el-input>
        </el-form-item>
      </el-form>



      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="centerDialog('addForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="centerDialogVisible1" width="20%">
      <div class="deleteCenter">
        确定删除该条信息?
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
    <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible1 = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="centerDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { boardList, boardAdd, boardUpdate, boardDelete, } from '../../api/dailyWork.js'
import eventBus from '../../api/NoticeEventBus.js'
// import demo from "./demo.vue"
export default {
  name: 'Feedback',
  data() {
    return {
      formInline: {
        title: '',//标题
        content: '',//内容
        startTime: null,//创建时间
        endTime: null,
        userName: '',//创建人
      },
      tableData: [],
      dataLoading: false,
      centerDialogVisible: false,
      centerDialogVisible1: false,
      replyDialogVisible: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: '',
      textarea: 0,
      addForm: {
        sort: '',
        content: '',
        title: '',
        status: 0,
      },
      updateType: 0,
      id: '',
      rules: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^(?=.*[0-9])\d*$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入包含0的正整数"));
              }
            },
            trigger: "blur"
          }
        ],
        status: [
          { required: true, message: '请选择是否展示', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入公告内容', trigger: 'blur' },
        ],
      },
      authorityListBtn: []

    };
  },
  // components:{demo},

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    this.getList()
    this.authorityListBtn = sessionStorage.getItem('roleInfo')

  },
  created() {
    this.$store.commit('increment', '日常办公')
    this.$store.commit('selectChild', '公告管理')
    this.$store.commit("selectChildren", "");
    // this.getList()
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "currentPage": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
  },


  methods: {
    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    //序号
    getIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    //关闭弹窗
    handleClose() {
      this.addForm = this.$options.data().addForm
      this.$refs.addForm.clearValidate();
      this.getList()
    },
    //删除 centerDelete
    centerDelete() {
      boardDelete(this.id).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.centerDialogVisible1 = false,
            this.$message({
              message: res.message,
              type: 'success',
              duration: 3000,
              customClass: "messageText",
            });
            eventBus.$emit('notices-updated-notice');
          this.getList()
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })

    },
    //新增/修改
    centerDialog(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let obj = {
            content: this.addForm.content,
            sort: this.addForm.sort,
            title: this.addForm.title,
            status: this.addForm.status
          }
          let obj1 = {
            content: this.addForm.content,
            sort: this.addForm.sort,
            title: this.addForm.title,
            id: this.addForm.id,
            status: this.addForm.status
          }
          let func = this.updateType == 0 ? boardAdd(obj) : boardUpdate(obj1)

          func.then(res => {
            console.log(res);
            if (res.code == 200) {
              this.centerDialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.addForm = this.$options.data().addForm
              eventBus.$emit('notices-updated-notice');
              this.getList()
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })
        } else {
          return false
        }
      });
    },
    //列表查询
    getList() {
      this.dataLoading = true
      let obj = {
        ...this.formInline,
        pageNum: this.currentPage,
        pageSize: this.pageSize,

        // userName:''
      }

      boardList(obj).then(res => {
        this.dataLoading = false
        if (res.code == 200) {
          this.tableData = res.data
          this.total = res.total
          // this.$router.go(0)
         
          // this.$message({
          //       message: res.message,
          //       type: 'success',
          //       duration:3000,
          //       customClass: "messageText",
          //     });
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //回复
    replyDialog(el, type) {
      this.updateType = type
      console.log(el);
      this.addForm = el
      this.centerDialogVisible = true
    },
    //查询列表
    onSubmit() {
      this.getList()
    },

    //打开意见弹窗
    openViewDialog(el, type) {
      this.updateType = type
      this.centerDialogVisible = true
    },
    openViewDialog1(el) {
      this.id = el.id
      this.centerDialogVisible1 = true
    },
    //分页相关function
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1
      this.getList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
      this.getList()
    },
    //输入校验
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      if (this.paginationEndIpt < 1) {
        this.paginationEndIpt = 1
      } else {
        this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
      }
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      // this.paginationEndIpt=''
      this.getList()
    }

  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  min-height: 781px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

/* .el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
} */

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #e8f4ff;
}


.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 14px 26px 26px 20px;
  background: #061b45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 11px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}



.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  padding: 0;
  margin: 0 7px;
  color: #92b5da;
  /* height: 16px; */
}

/* .paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
} */

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
}

.newCenterText {
  width: 120px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
  font-family: "Microsoft YaHei", Arial, sans-serif;
}

.custom-input-textarea ::placeholder {
  color: #6180a8;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.timeAt {
  display: flex;
}

.box {
  width: 50px;
  height: 50px;
  background: #e8f4ff;
}
</style>
<style>
.custom-tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}
</style> 